<template>
  <div style="height: 100%">
    <el-container style="height: 100%">
      <!-- / 左侧菜单 -->
      <el-aside style="width: auto !important">
        <frAside></frAside>
      </el-aside>

      <el-container>
        <!-- / 头部标题 -->
        <el-header></el-header>

        <!-- S 左侧主体 -->
        <el-main class="fr_main_div">
          <el-row :gutter="35">
            <!-- / 走马灯 和文章列表-->
            <el-col :lg="18">
              <el-carousel
                indicator-position="outside"
                :height="rotationHeight"
                class="carousel_div"
              >
                <el-carousel-item
                  v-for="(item, index) in carouselList"
                  :key="index"
                >
                  <a :href="item.url" target="_back">
                    <el-image
                      class="fengrui_img"
                      style="border-radius: 16px"
                      :src="item.img"
                      fit="cover"
                    ></el-image>
                  </a>
                </el-carousel-item>
              </el-carousel>

              <h2 class="list_h">最新列表</h2>

              <el-row :gutter="20">
                <el-col
                  :lg="8" :sm="24"
                  v-for="(center, index) in centerList"
                  :key="index"
                >
                  <div class="list_img" @click="drawerTap(index)">
                    <el-image
                      class="fengrui_img"
                      :src="center.img"
                      fit="cover"
                    ></el-image>
                  </div>
                  <div class="list_title">{{ center.title }}</div>
                </el-col>
              </el-row>
            </el-col>

            <!-- / 右侧小组件 -->
            <el-col :lg="6" :sm="24">
              <div class="right_nice">
                <div class="right_nice_h">公告栏</div>
                <div class="right_nice_font">
                  {{ notice.nt_center }}
                </div>
                <div class="right_nice_img">
                  <el-image
                    class="fengrui_img"
                    :src="require('../../assets/imgs/Saly-12.png')"
                    fit="cover"
                  ></el-image>
                </div>
              </div>

              <div
                class="right_other_img"
                v-for="(adtool, index) in advertTools"
                :key="index"
              >
                <a :href="adtool.ad_tools_url" target="_back">
                  <el-image class="fengrui_img" :src="adtool.ad_tools_img">
                  </el-image>
                </a>
              </div>
            </el-col>
          </el-row>
        </el-main>
        <!-- E 左侧主体 -->
      </el-container>
    </el-container>

    <!-- S 抽屉弹窗 -->
    <el-drawer title="插画详情" :visible.sync="drawer" size="84%">
      <div class="drawer_div">
        <el-row :gutter="35">
          <el-col :lg="15" :sm="24">
            <div class="drawer_img">
              <el-image class="fengrui_img" :src="listsData.img" alt="">
              </el-image>
            </div>
            <el-row :gutter="35">
              <el-col :lg="8" :sm="24">
                <div class="drawer_tool">
                  <div class="drawer_tool_img">
                    <el-image
                      class="fengrui_img"
                      :src="require('../../assets/imgs/Saly-11.png')"
                    ></el-image>
                  </div>
                  <div class="drawer_bg">
                    <div class="drawer_font">爱而不得是一种遗憾</div>
                    <a class="down_a" :href="userInfo.us_aliplay" type="_back">
                      <el-button class="drawer_aliplay_btn"
                        >支付宝赞赏</el-button
                      >
                    </a>
                  </div>
                </div>
              </el-col>
              <el-col :lg="8" :sm="24">
                <div class="drawer_tool">
                  <div class="drawer_tool_img">
                    <el-image
                      class="fengrui_img"
                      :src="require('../../assets/imgs/Saly-10.png')"
                    ></el-image>
                  </div>
                  <div class="drawer_bg">
                    <div class="drawer_font">有这权利所以无从着力</div>
                    <a class="down_a" :href="userInfo.us_wxplay" type="_back">
                      <el-button class="drawer_watch_btn">微信赞赏</el-button>
                    </a>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :lg="8" :sm="24">
            <div class="user_div">
              <div class="user_img">
                <el-image
                  class="fengrui_img"
                  :src="require('../../assets/imgs/drawer_user.png')"
                ></el-image>
              </div>
              <div class="user_info">
                <div class="user_info_name">{{ userInfo.us_name }}</div>
                <div class="user_info_dsrc">{{ userInfo.us_dsrc }}</div>
                <div class="user_info_contact">
                  <el-tooltip
                    effect="dark"
                    :content="userInfo.us_qq"
                    placement="bottom"
                  >
                    <div class="user_ic_icon">
                      <el-image
                        class="fengrui_img"
                        :src="
                          require('../../assets/imgs/drawer_tool_img_qq.svg')
                        "
                      ></el-image>
                    </div>
                  </el-tooltip>

                  <el-tooltip
                    effect="dark"
                    :content="userInfo.us_wechat"
                    placement="bottom"
                  >
                    <div class="user_ic_icon">
                      <el-image
                        class="fengrui_img"
                        :src="
                          require('../../assets/imgs/drawer_tool_img_watchat.svg')
                        "
                      ></el-image>
                    </div>
                  </el-tooltip>

                  <el-tooltip
                    effect="dark"
                    :content="userInfo.us_email"
                    placement="bottom"
                  >
                    <div class="user_ic_icon">
                      <el-image
                        class="fengrui_img"
                        :src="
                          require('../../assets/imgs/drawer_tool_img_email.svg')
                        "
                      ></el-image>
                    </div>
                  </el-tooltip>
                </div>
              </div>
            </div>

            <div class="down_div">
              <div class="down_title">Banner插画下载</div>
              <div class="down_dsrc">你喜欢的人会让你长大</div>
              <a :href="listsData.down" target="_back" class="down_a"
                ><el-button class="down_btn"> 点击下载</el-button></a
              >
            </div>

            <div class="edition_div" style="background: #5e81f4">
              <div class="edition_vt">Qu</div>
              <div>
                <div class="edition_title">如果下载源码出现问题</div>
                <div class="edition_nabuer">请阅读菜单中的“教程/问题”栏目</div>
              </div>
            </div>

            <div class="edition_div">
              <div class="edition_vt">Vt</div>
              <div>
                <div class="edition_title">请使用以下版本</div>
                <div class="edition_nabuer">{{ listsData.edition }}</div>
              </div>
            </div>

            <div class="edition_div" style="background: rgba(82, 170, 196, 1)">
              <div class="edition_vt">Ft</div>
              <div>
                <div class="edition_title">插画字体说明</div>
                <div class="edition_nabuer">{{ listsData.font }}</div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </el-drawer>
    <!-- E 抽屉弹窗 -->
  </div>
</template>
<script>
import axios from "axios";
import { GetfrList, GetfrBasic } from "../../components/api";
import aside from "../../components/aside.vue";
export default {
  components: {
    frAside: aside,
  },
  data() {
    return {
      // 弹窗抽屉
      drawer: false,
      // 轮播图
      carouselList: [],
      rotationHeight:'252px',
      // 列表
      centerList: [],
      // 右侧小广告
      advertTools: [],
      // 点击后弹窗数据
      listsData: [],

      // 公告
      notice: {},
      userInfo: {},
    };
  },
  mounted() {
    // 请求数据
    this.frDate();
    // this.setMenPath();
  },
  created() {
    // 判断分辨率
    var smMedia = window.matchMedia("(max-width:768px)");
    console.log(smMedia);
    if(smMedia.matches == true){
      this.rotationHeight = '130px';
    }
  },
  methods: {
    // 获取数据
    frDate() {
      // 请求列表 轮播图
      axios
        .get(GetfrList)
        .then((res) => {
          this.carouselList = res.data.carousel_list;
          this.centerList = res.data.center_list;
          // console.log(this.centerList);
        })
        .catch((error) => console.log(error));

      // 请求广告 用户社交信息
      axios
        .get(GetfrBasic)
        .then((res) => {
          this.notice = res.data.notice;
          this.advertTools = res.data.advert_tools;
          this.userInfo = res.data.user_info;
          console.log(this.userInfo);
        })
        .catch((error) => console.log(error));
    },

    // 列表点击
    drawerTap(e) {
      this.drawer = true;
      this.listsData = this.centerList[e];
      // console.log(this.listsData);
    },
    setMenPath() {
      this.$store.commit("updateMenPath", "0");
    },
  },
};
</script>
<style scoped>
/* 弹窗内容 右侧信息 */
.edition_nabuer {
  font-size: 16px;
  font-weight: 500;
  color: #fefefe;
  margin-top: 6px;
}
.edition_title {
  font-size: 12px;
  font-weight: 400;
  color: #fefefe;
  opacity: 0.8;
}
.edition_vt {
  width: 52px;
  height: 52px;
  background: rgba(254, 254, 254, 0.15);
  border-radius: 11px;
  color: #fff;
  font-weight: 500;
  text-align: center;
  line-height: 52px;
  margin-right: 18px;
}
.edition_div {
  display: flex;
  background: #ff808b;
  border-radius: 18px;
  margin-bottom: 24px;
  padding: 24px;
}
.down_btn {
  width: 90%;
  height: 50px;
  background: #99aad3;
  border: #99aad3;
  border-radius: 18px;
  color: #fff;
  margin: 30px 0 10px 5%;
}
.down_dsrc {
  margin-top: 16px;
  font-size: 14px;
  font-weight: 400;
  color: #ffffff;
}
.down_title {
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
}
.down_a {
  color: #fff;
  text-decoration: none;
}
.down_div {
  background: #5e6f98;
  border-radius: 18px;
  padding: 24px;
  margin-bottom: 24px;
}
.user_ic_icon {
  overflow: hidden;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  margin-right: 20px;
}
.user_info_contact {
  display: flex;
  align-items: center;
}
.user_info_dsrc {
  font-size: 11px;
  font-weight: 500;
  color: #a2a2c2;
  margin-bottom: 16px;
}
.user_info_name {
  font-size: 23px;
  font-weight: 500;
  color: #111026;
}
.user_info {
  margin-left: 20px;
}
.user_img {
  height: 177px;
  width: 177px;
  flex-shrink: 0;
}
.user_div {
  display: flex;
  align-items: center;
  width: 100%;
  height: 177px;
  background: #edf0f7;
  opacity: 1;
  border-radius: 18px;
  margin-bottom: 24px;
}

/* 弹窗内容 左侧内容 */
.drawer_bg {
  padding: 80px 0 24px 0;
  margin-top: -90px;
  background: #f5f5fb;
  border-radius: 15px;
}
.drawer_aliplay_btn {
  width: 156px;
  background-color: #5e81f4;
  border-radius: 15px;
  border: #5e81f4;
  color: #fff;
}
.drawer_watch_btn {
  width: 156px;
  background-color: #52aac4;
  border-radius: 15px;
  border: #52aac4;
  color: #fff;
}
.drawer_tool_img {
  height: 180px;
  width: 180px;
  margin: auto;
}
.drawer_font {
  margin: 20px 15%;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #a2a2c2;
}
.drawer_tool {
  border-radius: 15px;
  height: 238px;
  text-align: center;
  margin-top: 20px;
}
.drawer_img {
  overflow: hidden;
  height: 464px;
  width: 100%;
  border-radius: 18px;
  box-shadow: #e7e7e7 6px 6px 16px;
}
.drawer_div {
  padding: 0 36px;
}

/* 右侧小组件 */
.right_other_img {
  margin-bottom: 18px;
  height: auto;
  width: 100%;
  border-radius: 18px;
}

/* .el-image {
  border-radius: 18px;
} */

.other {
  overflow: hidden;
  border-radius: 18px;
}

.right_nice_img {
  margin: 20px auto 0 auto;
  overflow: hidden;
  height: 200px;
  width: 200px;
}

.right_nice_font {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
}
.right_nice_h {
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
}
.right_nice {
  padding: 24px;
  height: auto;
  border-radius: 18px;
  background-color: #ff808b;
  margin-bottom: 18px;
}

/* 列表 */
.list_title {
  margin: 14px 0;
  height: 44px;
  font-size: 16px;
  color: #111026;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list_img {
  overflow: hidden;
  margin-top: 22px;
  width: 100%;
  height: 164px;
  border-radius: 15px;
}
.list_img:hover {
  box-shadow: #b1b1b1 6px 6px 12px;
}

/* 最新 */
.list_h {
  color: #111026;
  /* margin: 15px 0; */
}

/* carousel走马灯 */
.carousel_div {
  border-radius: 16px;
}

/* macin主体内容 */
.fr_main_div .el-header {
  background: rgb(238, 240, 227);
}

.el-aside {
  background-color: #ffffff;
}
/* css3媒体查询 */
@media (max-width: 760px) {
  .list_h{
    font-size: 20px;
  }
  .list_img{
    margin-top: unset;
  }
  .drawer_img{
    height: 164px;
  }
  .user_img{
    display: none;
  }
  .user_div{
    margin-top: 80px;
  }
}
/* 深色模式 */
@media (prefers-color-scheme: dark) { 
  .el-main,.el-header{ 
    background-color: #1f2a38 !important;
  }
  .list_h,.list_title{
    color: #ffffff;
  }
  .drawer_img{
    box-shadow: unset;
  }
  ::v-deep .el-drawer{
    background-color: #1f2a38 !important;
  }
}
</style>
